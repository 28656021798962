import noop from 'lodash/noop';
import { useCallback } from 'react';

import * as Analytics from 'services/analytics';
import * as ErrorLogging from 'services/ErrorLogging';

/**
 * For wrapping together a callback function (usually a change or click handler)
 * and analytics connected to those events
 * Example:
  const MealComponent = () => {
    const [servings, setServings] = useState(2);
    const onChange = useAnalyticsCallback(
      updatedServings => setServings(updatedServings),
      ({ logEvent, events, callbackArgs, callbackFnResult }) =>
        logEvent(events.changedServings, { servings: callbackArgs[0], result: callbackFnResult }),
      []
    )
    return <Servings onChange={onChange} servings={servings} />;
  }
 * @param {function|null} callbackFn - optional, falls back to noop function if null
 * @param {function} analyticsCallback
 * @param {array|undefined} dependencies - React hook dependencies
 *  If no dependencies are provided, falls back to [callbackFn, analyticsCallback]
 *  to return a new function if either of those functions change
 * @return {function} - memoized based on dependencies like any useCallback React hook
 */
const useAnalyticsCallback = (callbackFn, analyticsCallback, dependencies) => {
  callbackFn = callbackFn || noop;
  dependencies = dependencies || [callbackFn, analyticsCallback];

  return useCallback((...callbackFnArgs) => {
    const callbackFnResult = callbackFn(...callbackFnArgs);

    // Handle the result based on whether it's a Promise
    if (callbackFnResult instanceof Promise) {
      // For async callbacks, return a Promise that includes analytics tracking
      return callbackFnResult.then(resolvedResult => {
        try {
          analyticsCallback({
            callbackArgs: callbackFnArgs,
            logEvent: Analytics.logEvent,
            events: Analytics.events,
            callbackFnResult: resolvedResult,
          });
        } catch (e) {
          ErrorLogging.captureException(e.message);
        }
        return resolvedResult;
      });
    } else {
      // For synchronous callbacks, track analytics immediately and return the result
      try {
        analyticsCallback({
          callbackArgs: callbackFnArgs,
          logEvent: Analytics.logEvent,
          events: Analytics.events,
          callbackFnResult,
        });
      } catch (e) {
        ErrorLogging.captureException(e.message);
      }
      return callbackFnResult;
    }
  }, dependencies);
};

export default useAnalyticsCallback;
